<template>
	<!-- 充值订单 -->
	<div class="account-recharge-record">
		<div class="el-content mb12">
			<div class="kd-pacel">检索参数</div>
			<a-space>
				<a-input v-model="search.order_number" style="width: 300px;" placeholder="订单号">
					<template #addonBefore>
						<a-select style="width: 120px" v-model="search.pay_status" placeholder="状态">
							<a-select-option value="">全部</a-select-option>
							<a-select-option value="1">已支付</a-select-option>
							<a-select-option value="0">未支付</a-select-option>
						</a-select>
					</template>
				</a-input>
				<a-input-search 
					v-model="search.account_id" 
					style="width: 300px;" 
					placeholder="用户userid" 
					enter-button 
					@search="getRechargeOrder(1,info.limit)" 
				/>
			</a-space>
		</div>
		
		<div class="el-content">
			<a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
				{title:'id',dataIndex:'id'},
				{title:'userId',dataIndex:'account_id'},
				{title:'订单号',dataIndex:'order_number'},
				{title:'充值金额(元)',dataIndex:'total_price'},
				{title:'支付状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'支付方式',dataIndex:'pay_method',slots:{customRender:'pay_method'}},
				{title:'支付时间',dataIndex:'pay_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
				<template #status="{record}">
				   <a-tag :color="getStatus(record,2)">{{ getStatus(record,1) }}</a-tag>
				</template> 
				<template #pay_method="{record}">
					{{$util.payMethod(record.pay_method)}}
				</template> 
				<template #action="{record}">
					<a-button
						size="small"
						type="danger" 
						v-if="record.is_refund!=1 && record.pay_status == 1" 
						@click="orderRefund(record.id)" 
						v-has="{action:'wxapp_account_recharge_order_refund',plat:0}">
						退款
					</a-button>
				</template> 
			</a-table>
			<div class="pager">
				<a-pagination show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getRechargeOrder(info.page,e)}"
					@change="(e)=>{getRechargeOrder(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import userModel from '@/api/user.js'
import { computed, reactive, toRefs } from 'vue'
export default{
	setup(){
		const _d = reactive({
			search:{
				order_number:"",
				account_id:"",
				pay_status:""
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			}
		})
		const getStatus = computed(()=>{
			return function(data,type){
				if( data.is_refund == 1 ) return type == 1 ? '已退款':'#FF3366'
				if( data.is_cancel == 1 ) return type == 1 ? '已取消':'#FF3366'
				if( data.pay_status == 1 ) return type == 1 ? '已支付':'#00CC66'
				if( data.pay_status == 0 ) return type == 1 ? '未支付':'#999'
			}
		})
		getRechargeOrder(1,_d.info.limit)
		function getRechargeOrder( page,limit ){
			userModel.getRechargeOrder(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		const orderRefund = (id)=>userModel.refundRechargeOrder(id,()=>getRechargeOrder(_d.info.page,_d.info.limit))
		return{
			...toRefs(_d),
			getStatus,
			getRechargeOrder,
			orderRefund
		}
	}
}
</script>
<style lang="scss">
</style>
